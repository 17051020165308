import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import { Button, Modal } from "flowbite-react";
import { url } from "inspector";
import React, { useCallback, useEffect, useState } from "react";
import { BiError } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import ReactModal from "react-modal";
import logo from "assets/img/logo.svg";
import { regions } from "utils/regions";
import { useNavigate } from "react-router";

export function RegionSelectorModal() {
  const modalID = "region-selector";
  const navigate = useNavigate();

  const [regionList, setRegionList] = useState(regions);

  const [modalIsOpen, setIsOpen] = useState(false);
  //   const [modalData, setModalData] = useState<any>();
  //   const { refreshUser, user } = useAuth();
  const { openModal, closeModal, modalData } = useModal();
  // const { children } = props;

  ReactModal.setAppElement("#modal");

  function afterOpenModal() {
    //
  }

  function handleCloseModal() {
    closeModal();
  }

  const goToRegion = useCallback(
    (region: any) => {
      navigate(`/region/${region.id}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (openModal === modalID) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openModal]);

  return (
    <ReactModal
      shouldCloseOnOverlayClick={false}
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={handleCloseModal}
      contentLabel="Game Modal"
      portalClassName="relative z-50"
      overlayClassName="fixed z-100 top-0 left-0 right-0 bottom-0 bg-black bg-opacity-80 p-6 md:flex md:justify-center md:items-center"
      contentElement={() => (
        <>
          <div className="z-20 flex flex-col gap-4 px-6 py-6 md:px-10 justify-between items-center bg-white ring-2 ring-white shadow-2xl rounded-xl overflow-y-auto md:overflow-hidden relative w-full h-full m-auto max-w-[900px] md:max-h-[540px]">
            <button
              onClick={handleCloseModal}
              className="absolute z-40 bg-brand-red text-white p-2 rounded-full top-2 right-2"
            >
              <IoMdClose size={28} />
            </button>

            <img src={logo} alt="CRECER PARA SER" className="w-1/4" />
            <p className="text-xl text-center py-5">Ingresa a una región:</p>
            <div className="flex-1 w-full">
              <div className="grid gap-6 grid-cols-2 md:grid-cols-4 lg:container-md items-center justify-center ">
                {regionList.map((region) => (
                  <div
                    key={region.id}
                    onClick={() => goToRegion(region)}
                    className={`relative group`}
                  >
                    <div
                      className={`ring ring-white w-full aspect-square rounded-full bg-white flex items-center justify-center overflow-hidden relative cursor-pointer animate transition-transform hover:scale-105 hover:ring hover:ring-white hover:shadow-glow 
    
`}
                    >
                      <img
                        src={region.thumbnail}
                        alt={region.name}
                        className={`w-full h-full object-cover absolute`}
                      />
                    </div>
                    {
                      <div
                        className={`leading-tight w-full mt-4 text-brand-dark-blue font-bold rounded-xl text-center text-sm pointer-events-none`}
                      >
                        <span>{region.name}</span>
                      </div>
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    />
  );
}
