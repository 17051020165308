import content from "pages/Region/content";

const darklands = require("assets/img/region/darklands.jpg");
const junkcity = require("assets/img/region/junkcity.jpg");
const oceanOfFeelings = require("assets/img/region/ocean-of-feelings.jpg");
const prosperityForest = require("assets/img/region/prosperity-forest.jpg");

const thumbnail_darklands = require("assets/img/region/thumbnail-darklands.jpg");
const thumbnail_junkcity = require("assets/img/region/thumbnail-junkcity.jpg");
const thumbnail_oceanOfFeelings = require("assets/img/region/thumbnail-ocean-of-feelings.jpg");
const thumbnail_prosperityForest = require("assets/img/region/thumbnail-prosperity-forest.jpg");

const thumbnail_ash = require("assets/img/characters/thumbnail-ash.jpg");
const thumbnail_evolbot = require("assets/img/characters/thumbnail-evolbot.jpg");
const thumbnail_alma = require("assets/img/characters/thumbnail-alma.jpg");
const thumbnail_fortunio = require("assets/img/characters/thumbnail-fortunio.jpg");

const portrait_ash = require("assets/img/characters/portrait-ash.jpg");
const portrait_evolbot = require("assets/img/characters/portrait-evolbot.jpg");
const portrait_alma = require("assets/img/characters/portrait-alma.jpg");
const portrait_fortunio = require("assets/img/characters/portrait-fortunio.jpg");



const regions: any[] = [
  {
    id: "darklands",
    name: "Tierras Oscuras",
    image: darklands,
    thumbnail: thumbnail_darklands,
    character: thumbnail_ash,
    portrait: portrait_ash,
  },
  {
    id: "junkcity",
    name: "Ciudad Chatarra",
    image: junkcity,
    thumbnail: thumbnail_junkcity,
    character: thumbnail_evolbot,
    portrait: portrait_evolbot,
  },
  {
    id: "ocean-of-feelings",
    name: "Océano de las Emociones",
    image: oceanOfFeelings,
    thumbnail: thumbnail_oceanOfFeelings,
    character: thumbnail_alma,
    portrait: portrait_alma,
  },
  {
    id: "prosperity-forest",
    name: "Bosque de la Prosperidad",
    image: prosperityForest,
    thumbnail: thumbnail_prosperityForest,
    character: thumbnail_fortunio,
    portrait: portrait_fortunio,
  },
];

const characters: any = {
  "Ash": thumbnail_ash,
  "Evolbot": thumbnail_evolbot,
  "Alma": thumbnail_alma,
  "Fortunio": thumbnail_fortunio,
}

const getRegionById = (id: string) => {
  return regions.find((region) => region.id === id);
};

const getCharacterById = (id: string) => {
  try {
    return characters[id];
  } catch (error) {
    return null;
  }
}

export { regions, getRegionById, getCharacterById };
