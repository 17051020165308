import { AchievementModal } from "components/modal/achievement";
import { AvatarModal } from "components/modal/avatar";
import { BadgeModal } from "components/modal/badge";
import FriendInviteModal from "components/modal/friend-invite";
import { GameModal } from "components/modal/game";
import { LifeSkillSelectorModal } from "components/modal/lifeskill-selector";
import MessageComposerModal from "components/modal/message-composer";
import { PopupMissionModal } from "components/modal/popup-mission";
import { PurchaseModal } from "components/modal/purchase";
import PurchaseTermsModal from "components/modal/purchase-terms";
import RealLifeAnswerModal from "components/modal/real-life-answer";
import RealLifeReviewModal from "components/modal/real-life-review";
import { RegionSelectorModal } from "components/modal/region-selector";
import Notifications from "components/notifications";
import OnboardingNotifications from "components/onboarding-notifications";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

interface ModalContextData {
  showModal: (modalId: string, data?: any) => void;
  closeModal: () => void;
  openModal?: string;
  modalData: any;
}

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalContext = createContext({} as ModalContextData);

function ModalProvider({ children }: ModalProviderProps) {
  const location = useLocation();

  const [openModal, setOpenModal] = useState<string | undefined>();
  const [modalData, setModalData] = useState<any>();

  useEffect(() => {
    closeModal();
  }, [location]);

  function showModal(modalId: string, data?: any) {
    setModalData(data);
    setOpenModal(modalId);
  }

  function closeModal() {
    setOpenModal(undefined);
    setModalData(undefined);
  }

  return (
    <ModalContext.Provider
      value={{
        showModal,
        closeModal,
        openModal,
        modalData,
      }}
    >
      {children}
      <OnboardingNotifications />
      <Notifications />
      <AchievementModal />
      <GameModal />
      <BadgeModal />
      <AvatarModal />
      <PopupMissionModal />
      <LifeSkillSelectorModal />
      <RealLifeAnswerModal />
      <RealLifeReviewModal />
      <PurchaseModal />
      <PurchaseTermsModal />
      <MessageComposerModal />
      <FriendInviteModal />
      <RegionSelectorModal />
    </ModalContext.Provider>
  );
}

function useModal() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useWebsocket must be used within a WebsocketProvider");
  }
  return context;
}

export { ModalProvider, useModal };
