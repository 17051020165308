import { useCallback, useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { centeredContent } from "styles";
import { useAppStore } from "store";
import { useMount } from "react-use";
import Loading from "components/ui/loading";

interface IFormInput {
  username: string;
  password: string;
}

export function Login() {
  const navigate = useNavigate();
  const [invalidUser, setInvalidUser] = useState(false);

  const [params] = useSearchParams();
  // const { loginWithToken, login } = useContext(AuthContext);

  const [errors, setErrors] = useState("");
  const [info, setInfo] = useState("");

  const {
    setStoreHost,
    setShouldRedirect,
    loginWithToken,
    login,
    clearStoreUser,
  } = useAppStore();

  useMount(() => {
    clearStoreUser();
  });

  const redirect = useCallback(() => {
    setTimeout(() => {
      if (params.get("inviteCode")) {
        navigate(`/share?inviteCode=${params.get("inviteCode")}`);
        return;
      }

      if (window.self !== window.top) {
        setShouldRedirect(true);
        navigate("/");
      } else {
        setShouldRedirect(false);
        navigate("/");
      }
    }, 300);
  }, [navigate, setShouldRedirect, params]);

  useEffect(() => {
    async function doLogin(username: string, password: string) {
      try {
        await login({ username, password });
        redirect();
      } catch (e) {
        setInvalidUser(true);
        setErrors(JSON.stringify(e));
      }
    }

    async function doLoginWithToken(token: string) {
      try {
        await loginWithToken(token);
        redirect();
      } catch (e) {
        console.log(e);
        setInvalidUser(true);
        setErrors(JSON.stringify(e));
      }
    }

    async function init() {
      if (window.location.search) {
        const params = new URLSearchParams(window.location.search);
        const token = params.get("token");
        const agent = params.get("agent");
        const secret = params.get("secret");

        const url = window.location.href;

        setInfo(
          `Token: ${token}, Agent: ${agent}, Secret: ${secret}, URL: ${url}`,
        );

        if (token) {
          await doLoginWithToken(token);
        } else if (agent && secret) {
          await doLogin(agent, secret);
        } else {
          setInvalidUser(true);
        }
      }
    }

    init();

    // console.log(
    //   "login...",
    //   `Token: ${token}, Agent: ${agent}, Secret: ${secret}, Host: ${host}, URL: ${url}`
    // );
  }, [login, loginWithToken, redirect]);

  if (invalidUser) {
    return (
      <div>
        <p>
          <b>ERROR</b>
        </p>
        {/* <p>{errors}</p>
        <p className="small">{info}</p> */}
      </div>
    );
  }

  return (
    <div className={centeredContent}>
      <Loading />
    </div>
  );
}
