/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from "context/AuthContext";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useAppStore } from "store";

interface WebsocketContextData {
  // connect: () => void;
  // disconnect: () => void;
  sendMessage: (message: any) => void;
  setMessage: (data: any | undefined) => void;
  message: any;
}

interface WebsocketContextProps {
  children: ReactNode;
}

export const WebsocketContext = createContext({} as WebsocketContextData);

function WebsocketNotificationProvider({ children }: WebsocketContextProps) {
  const [client, setClient] = useState<any>();
  const [message, setMessage] = useState<any>();
  const { token, refreshUser, checkUnreadNotifications } = useAppStore();

  const connect = useCallback(() => {
    try {
      if (client) {
        client.close();
      }
      // const token = getToken();
      if (token) {
        const socketClient = new WebSocket(
          `wss://service2.funifier.com/ws/notification?authorization=${encodeURIComponent(
            token,
          )}`,
        );
        setClient(socketClient);
      }
    } catch (e) {
      disconnect();
    }
  }, [token]);

  function disconnect() {
    if (client) {
      client.close();
    }
    setClient(undefined);
  }

  function onMessage(e: MessageEvent) {
    // setMessage(JSON.parse(e.data));

    const messageData = JSON.parse(e.data);

    if (messageData.type === "notification") {
      toast(`🔔 Nueva mensaje: ${messageData.content}`, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
      });
      checkUnreadNotifications();
      return;
    }

    window.postMessage(
      {
        type: "achievement",
        value: {
          name: "notificationMessage",
          achievement: JSON.parse(e.data),
        },
      },
      "*",
    );

    refreshUser();

    dispatchEvent(
      new CustomEvent("websocket-message", { detail: JSON.parse(e.data) }),
    );
  }

  function sendMessage(message: any) {
    if (!client) return;
    client.send(JSON.stringify(message));
  }

  useEffect(() => {
    connect();
  }, [token]);

  useEffect(() => {
    if (client) {
      client.onmessage = onMessage;
    }
    return () => {
      if (client) {
        client.onmessage = null;
      }
    };
  }, [client]);

  return (
    <WebsocketContext.Provider
      value={{
        sendMessage,
        message,
        setMessage,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
}

function useWebsocketNotification() {
  const context = useContext(WebsocketContext);
  if (context === undefined) {
    throw new Error(
      "useWebsocketNotification must be used within a WebsocketProvider",
    );
  }
  return context;
}

export { WebsocketNotificationProvider, useWebsocketNotification };
