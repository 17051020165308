import { CheckCircle, UserPlus } from "@phosphor-icons/react";
import { useModal } from "context/ModalContext";
import { Button, Textarea } from "flowbite-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaCopy, FaWhatsapp } from "react-icons/fa";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { getMessageTemplates, sendMessage } from "services/messages.service";
import { useAppStore } from "store";

const isProduction = window.location.hostname === "teensmart.funifier.app";

let baseUrl = `https://dev.jovensalud.net`;

if (isProduction) {
  baseUrl = `https://jovensalud.net`;
}

export default function FriendInviteModal() {
  const { user, host } = useAppStore();

  const modalID = "friend-invite";

  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { openModal, closeModal } = useModal();

  ReactModal.setAppElement("#modal");

  const handleInvite = useCallback(() => {
    const inviteCode = user?.extra.inviteCode;

    const link = `${baseUrl}/invitacion-amigo/${inviteCode}`;

    // create a message for sharing via whatsapp
    const message = `¡Hey, únete a CRECER Para SER conmigo! 🚀\n\nEs un juego 🎮 para crecer como persona donde podemos hacer misiones juntos. 💪 Regístrate o inicia sesión en JovenSalud usando este enlace para que nuestros usuarios queden conectados automáticamente: ${link}\n\nMi usuario es: *${user?.extra.nombreUsuario}*. 🌟`;

    // encode the message properly
    const encodedMessage = encodeURIComponent(message);

    // if desktop open the web.whatsapp.com
    // if mobile open the whatsapp app

    let url = `whatsapp://send?text=${encodedMessage}`;

    if (window.innerWidth > 480) {
      url = `https://web.whatsapp.com/send?text=${encodedMessage}`;
    }

    // open the url
    window.open(url, "_blank");
  }, [user]);

  const handleCopyInvite = useCallback(() => {
    const inviteCode = user?.extra.inviteCode;

    const link = `${baseUrl}/invitacion-amigo/${inviteCode}`;

    // create a message for sharing via whatsapp
    const message = `¡Hey, únete a CRECER Para SER conmigo! 🚀\n\nEs un juego 🎮 para crecer como persona donde podemos hacer misiones juntos. 💪 Regístrate o inicia sesión en JovenSalud usando este enlace para que nuestros usuarios queden conectados automáticamente: ${link}\n\nMi usuario es: *${user?.extra.nombreUsuario}*. 🌟`;

    navigator.clipboard.writeText(message);

    toast.success("¡Mensaje copiado!");
  }, [user]);

  const renderMessage = useMemo(() => {
    const inviteCode = user?.extra.inviteCode;

    const link = `${baseUrl}/invitacion-amigo/${inviteCode}`;

    // create a message for sharing via whatsapp
    const message = `¡Hey, únete a CRECER Para SER conmigo! 🚀\n\nEs un juego 🎮 para crecer como persona donde podemos hacer misiones juntos. 💪 Regístrate o inicia sesión en JovenSalud usando este enlace para que nuestros usuarios queden conectados automáticamente: ${link}\n\nMi usuario es: *${user?.extra.nombreUsuario}*. 🌟`;

    return message;
  }, [user]);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    closeModal();
  }, [closeModal]);

  function afterOpenModal() {}

  useEffect(() => {
    if (openModal === modalID) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openModal]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-40"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 md:px-5 w-[450px] max-w-[97vw] max-h-[80vh] flex flex-col items-center flex-none mx-5 gap-3 overflow-y-auto">
              <div className="mt-2 flex justify-between items-center w-full">
                <h1 className="text-xl lg:text-3xl font-bold text-brand-red text-center">
                  INVITAR A UN AMIGO
                </h1>
                <UserPlus className="h-10 w-10 text-brand-red" />
              </div>
              <div className="w-full">
                <Textarea
                  className="w-full text-sm h-[200px]"
                  value={renderMessage}
                  readOnly
                  rows={4}
                ></Textarea>
              </div>
              <div className="py-3 relative z-10 flex w-full flex-col md:flex-row gap-2">
                <Button
                  fullSized
                  type="button"
                  onClick={() => handleCopyInvite()}
                >
                  <FaCopy className="h-6 w-6 mr-3" />
                  Copiar mensaje
                </Button>
                <Button
                  className="bg-green-500"
                  fullSized
                  type="button"
                  onClick={() => handleInvite()}
                >
                  <FaWhatsapp className="h-6 w-6 mr-3" />
                  <span>Whatsapp</span>
                </Button>
              </div>
              <div>
                <Button
                  fullSized
                  type="button"
                  onClick={() => handleCloseModal()}
                  className="bg-red-500"
                >
                  CERRAR
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
