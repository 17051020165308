import { Bell, Users, Gift, CheckCircle } from "@phosphor-icons/react";
import { IoIosPodium } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useAppStore } from "store";

export default function Tabbar() {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const navigate = useNavigate();
  const { hasUnreadNotifications } = useAppStore();

  if (!isMobile) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-20 px-5 flex flex-row bg-brand-orange justify-center">
      <div
        onClick={() => navigate("/notifications")}
        className="relative flex flex-col items-center shadow p-2 cursor-pointer hover:ring hover:ring-brand-orange text-white"
      >
        {hasUnreadNotifications ? (
          <div className="absolute -top-1 -right-1 w-5 h-5 bg-brand-red rounded-full"></div>
        ) : null}
        <div className="z-10 w-12 flex items-center justify-center text-white">
          <Bell size={30} />
        </div>
        <small>Mensajes</small>
      </div>

      <div
        onClick={() => navigate("/friends")}
        className="flex flex-col items-center shadow p-2 cursor-pointer hover:ring hover:ring-brand-orange text-white"
      >
        <div className="z-10 w-12 flex items-center justify-center text-white font-bold">
          <Users size={30} />
        </div>
        <small>Amigos</small>
      </div>

      <div
        onClick={() => navigate("/ranking")}
        className="flex flex-col items-center shadow p-2 cursor-pointer hover:ring hover:ring-brand-orange text-white"
      >
        <div className="z-10 w-12 flex items-center justify-center text-white">
          <IoIosPodium size={30} />
        </div>
        <small>Ranking</small>
      </div>

      <div
        onClick={() => navigate("/rewards-store")}
        className="flex flex-col items-center shadow p-2 cursor-pointer hover:ring hover:ring-brand-orange text-white"
      >
        <div className="z-10 w-12 flex items-center justify-center text-white">
          <Gift size={30} />
        </div>
        <small>Premios</small>
      </div>

      <div
        onClick={() => navigate("/badges")}
        className="flex flex-col items-center rounded-xl shadow p-2 cursor-pointer hover:ring hover:ring-brand-orange text-white"
      >
        <div className="z-10 w-12 flex items-center justify-center text-white">
          <CheckCircle size={30} />
        </div>
        <small>Logros</small>
      </div>
    </div>
  );
}
