import { api } from "./api";

export async function getMessageTemplates() {
  const { data } = await api.get(
    `database/message_templates__c`
  );
  return data;
}

export async function getMessages(player: string) {
  const { data } = await api.post(
    `find/notifications`,
    {
      player
    }
  );
  return data;
}

export async function sendMessage(payload: any) {
  if (payload._id) {
    const { data } = await api.put(
      `database/notifications__c`,
      payload
    );
    return data;
  }
  const { data } = await api.post(
    `database/notifications__c`,
    payload
  );
  return data;
}

export async function likeMessage(payload: any) {
  const { data } = await api.put(
    `database/notifications__c`,
    payload
  );
  return data;
}