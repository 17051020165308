/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";

import { Button } from "flowbite-react";
import Gem from "components/gem";
import { useModal } from "context/ModalContext";
import { clearNotifications } from "services/notifications.service";
import React from "react";
import { useAppStore } from "store";
import { getAllChallenges } from "services/challenges.service";
import { actionLog } from "services/actionlog.service";

export function PopupMissionModal(props: any) {
  ReactModal.setAppElement("#modal");

  const modalID = "popup-mission";

  const [modalIsOpen, setIsOpen] = useState(false);
  const { showModal, openModal, closeModal, modalData } = useModal();

  const { user, host, pointsHistory } = useAppStore();

  const [missions, setMissions] = useState<any[]>([]);

  async function handleCloseModal() {
    await clearNotifications();
    closeModal();
  }

  const goToContent = useCallback(
    (content: string, type: string, region?: string) => {
      let baseUrl = "https://dev.jovensalud.net";

      const isLocal = window.location.hostname === "localhost";

      const ref = region ? `ref=${region}` : "";

      if (host) {
        baseUrl = `https://${host}`;
      }

      if (content) {
        if (type === "articles") {
          if (!isLocal) {
            window.parent?.postMessage(
              {
                type: "goto",
                url: `/usuario/informate-ya?id=${content}&${ref}`,
              },
              "*",
            );
          } else {
            window.open(
              `${baseUrl}/usuario/informate-ya?id=${content}&${ref}`,
              "_blank",
            );
          }
        } else if (type === "quiz") {
          if (!isLocal) {
            window.parent?.postMessage(
              {
                type: "goto",
                url: `/usuario/conoce-tu-salud?id=${content}&${ref}`,
              },
              "*",
            );
          } else {
            window.open(
              `${baseUrl}/usuario/conoce-tu-salud?id=${content}&${ref}`,
              "_blank",
            );
          }
        } else if (type === "games") {
          showModal("game", content);
          actionLog("play_game", { content_id: content });
        } else {
          if (!isLocal) {
            window.parent?.postMessage(
              {
                type: "goto",
                url: content,
              },
              "*",
            );
          } else {
            window.open(content, "_blank");
          }
        }
      }
    },
    [host, showModal],
  );

  const handleGoTo = useCallback(() => {
    // handleCloseModal();
    // navigate("/app/missions");

    console.log("Go to missions", modalData);
    const { type, content, region } = modalData.extra;
    goToContent(content, type, region);
  }, [modalData, goToContent]);

  function afterOpenModal() {}

  const fetchPopupMission = useCallback(async () => {
    // setIsOpen(true);
    if (missions.length > 0) return;

    const result = await getAllChallenges({
      $match: {
        techniques: {
          $in: ["GT41"],
        },
      },
    });

    setMissions(result);
  }, [missions]);

  const checkPopupMission = useCallback(async () => {
    if (missions.length > 0) {
      const availablePopupMissions = missions.filter((item) => {
        let available = false;
        const requirements = item.requirements || [];
        if (requirements.length === 0) {
          available = true;
        }

        requirements.forEach((req: any) => {
          if (req.type === 0) {
            // console.log(req.item, pointsHistory[req.item], req.total);
            if (
              pointsHistory[req.item] &&
              pointsHistory[req.item] >= req.total
            ) {
              available = true;
            }
          }
        });

        return available;
      });

      if (availablePopupMissions.length === 0) {
        return;
      }

      const mission = availablePopupMissions[0];

      // console.log("mission", mission);

      let hasCompleted = false;

      Object.keys(user?.challenges || {}).forEach((key) => {
        if (key === mission._id) {
          // console.log("key", key);
          hasCompleted = true;
        }
      });

      // console.log("hasCompleted", hasCompleted);
      if (!hasCompleted) {
        showModal(modalID, mission);
      }
      // showModal(modalID, mission);
    }
  }, [showModal, missions, user, pointsHistory]);

  useEffect(() => {
    if (openModal === modalID) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openModal]);

  useEffect(() => {
    // fetch for pop-up missions
    // console.log("pop-up");
    fetchPopupMission();
  }, []);

  useEffect(() => {
    // fetch for pop-up missions
    // console.log("check pop-up");
    checkPopupMission();
  }, [user]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Badge Modal"
        portalClassName="relative z-40"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 px-10 max-w-[450px] flex flex-col items-center flex-none mx-5">
              <h1 className="text-xl lg:text-3xl mt-4 font-bold text-blue-500 text-center">
                ¡Nueva Misión!
              </h1>

              {modalData ? (
                <div className="relative w-[200px]">
                  {modalData?.badge ? (
                    <img
                      src={modalData?.badge.medium.url}
                      alt=""
                      className="w-full h-[200px] object-contain animate-wiggle"
                    />
                  ) : (
                    <div className="w-full h-[200px] relative animate-wiggle-zoom">
                      <div className="absolute top-[13%] -rotate-12">
                        <Gem type="knowledge" size={75} />
                      </div>
                      <div className="absolute left-[26%] bottom-[9%] rotate-6">
                        <Gem type="motivation" size={70} />
                      </div>
                      <div className="absolute top-[15%] right-0 rotate-3">
                        <Gem type="skill" size={80} />
                      </div>
                    </div>
                  )}
                </div>
              ) : null}

              <div>
                {modalData?.challenge && (
                  <p className="text-orange-500 font-bold text-center text-xl">
                    {modalData?.challenge}
                  </p>
                )}

                {modalData?.description && (
                  <p className="text-gray-500 text-center">
                    {modalData?.description}
                  </p>
                )}
              </div>

              <div className="py-3 relative z-10 flex gap-2">
                <Button
                  fullSized
                  type="button"
                  onClick={() => handleCloseModal()}
                  className="bg-red-500"
                >
                  CERRAR
                </Button>
                <Button fullSized type="button" onClick={() => handleGoTo()}>
                  EMPEZAR
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
