const iconSexuality = require("assets/img/life-skills/sexuality.png");
const iconEmotions = require("assets/img/life-skills/emotions.png");
const iconRelationship = require("assets/img/life-skills/relationship.png");
const iconEducation = require("assets/img/life-skills/education.png");

const bgSexuality = require("assets/img/life-skills/bg-sexuality.jpg");
const bgEmotions = require("assets/img/life-skills/bg-emotions.jpg");
const bgRelationship = require("assets/img/life-skills/bg-relationship.jpg");
const bgEducation = require("assets/img/life-skills/bg-education.jpg");

type LifeSkillContent = {
  id: string;
  name: string;
  image: string;
  background: string;
  color: string;
  description: string;
};

export const LIFESKILL_CONTENT = [
  {
    id: "education",
    name: "Potencia tu Aprendizaje",
    image: iconEducation,
    background: bgEducation,
    color: "bg-brand-red",
    description:
      "Un recorrido que te ayudará a mejorar tus habilidades académicas.",
  },
  {
    id: "emotion",
    name: "Bienestar emocional",
    image: iconEmotions,
    background: bgEmotions,
    color: "bg-brand-blue",
    description:
      "Un recorrido que te ayudará a mejorar tus emociones, a ver la vida de una forma más positiva.",
  },
  {
    id: "sexuality",
    name: "Sexualidad",
    image: iconSexuality,
    background: bgSexuality,
    color: "bg-brand-dark-green",
    description:
      "Un recorrido que te enseñará a tomar decisiones con respecto a tu sexualidad.",
  },
  {
    id: "relationship",
    name: "Relaciones interpersonales",
    image: iconRelationship,
    background: bgRelationship,
    color: "bg-brand-orange",
    description:
      "Un recorrido que te enseñará a construir relaciones saludables y satisfactorias.",
  }
];

export function getLifeSkillContent(id: string) {
  return LIFESKILL_CONTENT.find((item) => item.id === id) as LifeSkillContent;
}