import { CheckCircle } from "@phosphor-icons/react";
import { useModal } from "context/ModalContext";
import { Button, Textarea } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { getMessageTemplates, sendMessage } from "services/messages.service";
import { useAppStore } from "store";

export default function MessageComposerModal() {
  const { user } = useAppStore();

  const modalID = "message-composer";

  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState("");
  const { openModal, closeModal, modalData } = useModal();

  const [stickers, setStickers] = useState([]);
  const [currentSticker, setCurrentSticker] = useState<any>(null);

  ReactModal.setAppElement("#modal");

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    closeModal();
    setCurrentSticker(null);
    setMessage("");
  }, [closeModal]);

  const handleSelectSticker = (sticker: any) => {
    setMessage(sticker.message);
    setCurrentSticker(sticker);
  };

  const handleSubmit = useCallback(async () => {
    if (!modalData) return;

    if (!message) {
      alert("Debes escribir una mensaje.");
      return;
    }

    const payload = {
      to: [modalData.friend._id],
      content: message,
      template: currentSticker?._id,
      type: "notification",
      sender: "Custom",
      from: user?._id,
      time: new Date().getTime(),
    };

    setLoading(true);

    await sendMessage(payload);

    toast(`✅ Mensaje enviado`, {
      position: "top-right",
      closeOnClick: true,
      pauseOnHover: true,
    });

    setLoading(false);
    handleCloseModal();
  }, [message, modalData, handleCloseModal, user, currentSticker]);

  function afterOpenModal() {}

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const stickers = await getMessageTemplates();
      setStickers(stickers);
      setLoading(false);
    }

    if (openModal === modalID) {
      setIsOpen(true);
      loadData();
    } else {
      setIsOpen(false);
    }

    console.log("modalData", modalData);
  }, [openModal, modalData]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-40"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 md:px-5 w-[450px] max-w-[97vw] max-h-[80vh] flex flex-col items-center flex-none mx-5 gap-3 overflow-y-auto">
              <div className="mt-2 flex justify-between items-center w-full">
                <h1 className="text-xl lg:text-3xl font-bold text-blue-500 text-center">
                  ENVIAR MENSAJE
                </h1>
                <div className="flex items-center gap-2">
                  <img
                    src={modalData?.friend.image.original.url}
                    alt="avatar"
                    className="w-12 h-12 rounded-full"
                  />
                  <strong className="text-xl lg:text-3xl font-bold text-orange-500 text-center">
                    {modalData?.friend.name.toUpperCase()}
                  </strong>
                </div>
              </div>
              {/* <small>Escribe tu respuesta o sube una imagen o archivo.</small> */}
              {!loading ? (
                <>
                  <div className="w-full flex-1">
                    <h4 className="font-bold py-1">Seleccione un mensaje:</h4>
                    <div className="flex flex-col gap-3 p-2 overflow-y-auto h-[220px]">
                      {stickers.map((sticker: any) => (
                        <div
                          key={sticker._id}
                          onClick={() => handleSelectSticker(sticker)}
                          className={`flex items-center gap-2 cursor-pointer rounded-xl ${
                            currentSticker?._id === sticker._id
                              ? "bg-brand-green bg-opacity-10 ring-2 ring-brand-blue"
                              : "ring-1 ring-gray-200"
                          }`}
                        >
                          <img
                            src={sticker.image.original.url}
                            alt="sticker"
                            className="w-16 h-16 object-contain cursor-pointer border rounded-xl"
                          />
                          <span className="flex-1">{sticker.message}</span>
                          <div>
                            {currentSticker?._id === sticker._id ? (
                              <CheckCircle
                                size={32}
                                className="text-brand-orange mr-3"
                              />
                            ) : null}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* {message ? (
                    <div className="w-full flex flex-col gap-1">
                      <h4 className="font-bold">Mensaje:</h4>
                      <div className="border rounded-lg p-3">
                        {currentSticker?.message}
                      </div>
                    </div>
                  ) : null} */}

                  <div className="py-3 relative z-10 flex gap-2">
                    <Button
                      fullSized
                      type="button"
                      onClick={() => handleCloseModal()}
                      className="bg-red-500"
                    >
                      CERRAR
                    </Button>
                    <Button
                      fullSized
                      type="button"
                      onClick={() => handleSubmit()}
                    >
                      ENVIAR
                    </Button>
                  </div>
                </>
              ) : (
                <div className="py-3">
                  <p>CARGANDO...</p>
                </div>
              )}
            </div>
          </div>
        )}
      />
    </>
  );
}
