import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import { useAuth } from "context/AuthContext";
import { set } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useEvent, useMount } from "react-use";
import { useAppStore } from "store";
import { AvatarCharacterPreset, DefaultAvatarPreset } from "types/user";

const avatarPlaceholder = require("assets/img/avatar-silhouette.png");

const avatarRive = require("assets/rive/avatarcharacter.riv");
interface AvatarCharacterProps extends React.HTMLAttributes<HTMLDivElement> {
  preset?: AvatarCharacterPreset;
  responsive?: boolean;
  flip?: boolean;
}

const stateMachineName = "AvatarStateMachine";

export default function AvatarCharacter({
  flip,
  preset,
  responsive,
}: AvatarCharacterProps) {
  const { rive, RiveComponent } = useRive({
    src: avatarRive,
    stateMachines: stateMachineName,
    autoplay: true,
  });

  const [avatarPreset, setAvatarPreset] = useState<AvatarCharacterPreset>();
  const { user } = useAppStore();
  const [refreshing, setRefreshing] = useState(false);

  const skinInput = useStateMachineInput(rive, stateMachineName, "skin");
  const hairInput = useStateMachineInput(rive, stateMachineName, "hair");
  const faceInput = useStateMachineInput(rive, stateMachineName, "face");
  const accessoriesInput = useStateMachineInput(
    rive,
    stateMachineName,
    "accessories",
  );
  const shoesInput = useStateMachineInput(rive, stateMachineName, "shoes");
  const upperInput = useStateMachineInput(rive, stateMachineName, "upper");
  const lowerInput = useStateMachineInput(rive, stateMachineName, "lower");
  const bodyInput = useStateMachineInput(rive, stateMachineName, "body_props");
  const handsInput = useStateMachineInput(rive, stateMachineName, "hand_props");

  const hairColorInput = useStateMachineInput(
    rive,
    stateMachineName,
    "hair_color",
  );
  const shoesColorInput = useStateMachineInput(
    rive,
    stateMachineName,
    "shoes_color",
  );
  const upperColorInput = useStateMachineInput(
    rive,
    stateMachineName,
    "upper_color",
  );

  useMount(() => {
    rive?.cleanup();
    rive?.resizeToCanvas();
  });

  useEvent("resize", (e) => {
    // setRefreshing(true);
    // rive?.cleanup();
    const canvas = document.querySelector("canvas");
    if (canvas) {
      canvas.style.width = "100%";
      canvas.style.height = "100%";
    }
    rive?.resizeToCanvas();
    // console.log("resize", e.target);
    // setTimeout(() => {
    //   // rive?.resizeToCanvas();
    //   setRefreshing(false);
    // }, 1000);
  });

  useEffect(() => {
    if (!avatarPreset) return;

    if (skinInput) {
      skinInput.value = avatarPreset.skin;
    }
    if (bodyInput) {
      bodyInput.value = avatarPreset.body_props;
    }
    if (shoesInput) {
      shoesInput.value = avatarPreset.shoes;
    }
    if (handsInput) {
      handsInput.value = avatarPreset.hand_props;
    }
    if (hairInput) {
      hairInput.value = avatarPreset.hair;
    }
    if (upperInput) {
      upperInput.value = avatarPreset.upper;
    }
    if (lowerInput) {
      lowerInput.value = avatarPreset.lower;
    }
    if (faceInput) {
      faceInput.value = avatarPreset.face;
    }
    if (accessoriesInput) {
      accessoriesInput.value = avatarPreset.accessories;
    }

    if (hairColorInput) {
      hairColorInput.value = avatarPreset.hair_color;
    }
    if (shoesColorInput) {
      shoesColorInput.value = avatarPreset.shoes_color;
    }
    if (upperColorInput) {
      upperColorInput.value = avatarPreset.upper_color;
    }
  }, [
    avatarPreset,
    skinInput,
    bodyInput,
    shoesInput,
    handsInput,
    hairInput,
    upperInput,
    lowerInput,
    faceInput,
    accessoriesInput,
    hairColorInput,
    shoesColorInput,
    upperColorInput,
  ]);

  // useEffect(() => {
  //   if (!preset) return;
  //   setAvatarPreset(preset);
  // }, [preset]);

  useEffect(() => {
    // console.log("preset", user, preset);

    if (user && user.extra.avatar && !preset) {
      setAvatarPreset(user.extra.avatar);
    } else if (!preset) {
      const defaultPreset: AvatarCharacterPreset = { ...DefaultAvatarPreset };
      if (user?.extra.sexo) {
        if (user?.extra.sexo === "Masculino") {
          defaultPreset.hair = 4;
        } else {
          defaultPreset.hair = 1;
        }
      }
      setAvatarPreset(defaultPreset);
    } else {
      setAvatarPreset(preset);
    }
  }, [preset, user]);

  if (refreshing) return <></>;

  return (
    <div
      className={
        responsive
          ? `relative w-full aspect-[1/1.6]`
          : `w-64 relative aspect-[1/1.6]`
      }
    >
      {!avatarPreset ? (
        <></>
      ) : (
        <div>
          <RiveComponent
            className={`absolute w-full h-full ${flip ? "-scale-x-100" : ""}`}
          />
        </div>
      )}
    </div>
  );
}
