import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { Button } from "flowbite-react";
import Gem from "components/gem";
import { useModal } from "context/ModalContext";
import { clearNotifications } from "services/notifications.service";

export function BadgeModal(props: any) {
  ReactModal.setAppElement("#modal");

  const modalID = "badge";

  const [modalIsOpen, setIsOpen] = useState(false);
  const { openModal, closeModal, modalData } = useModal();

  async function handleCloseModal() {
    await clearNotifications();
    closeModal();
  }

  function afterOpenModal() {}

  useEffect(() => {
    if (openModal === modalID) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openModal]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Badge Modal"
        portalClassName="relative z-40"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 px-10 max-w-[450px] flex flex-col items-center flex-none mx-5">
              <h1 className="text-xl lg:text-3xl mt-4 font-bold text-blue-500 text-center">
                {modalData?.techniques && modalData?.techniques.includes("GT02")
                  ? `¡Insignia conseguida!`
                  : `¡Misión cumplida!`}
              </h1>

              {modalData ? (
                <div className="relative w-[200px]">
                  {modalData?.badge ? (
                    <img
                      src={modalData?.badge.medium.url}
                      alt=""
                      className="w-full h-[200px] object-contain animate-wiggle"
                    />
                  ) : (
                    <div className="w-full h-[200px] relative animate-wiggle-zoom">
                      <div className="absolute top-[13%] -rotate-12">
                        <Gem type="knowledge" size={75} />
                      </div>
                      <div className="absolute left-[26%] bottom-[9%] rotate-6">
                        <Gem type="motivation" size={70} />
                      </div>
                      <div className="absolute top-[15%] right-0 rotate-3">
                        <Gem type="skill" size={80} />
                      </div>
                    </div>
                  )}
                </div>
              ) : null}

              <div>
                {modalData?.challenge && (
                  <p className="text-orange-500 font-bold text-center text-xl">
                    {modalData?.challenge}
                  </p>
                )}

                {modalData?.description && (
                  <p className="text-gray-500 text-center">
                    {modalData?.description}
                  </p>
                )}
              </div>

              <div className="py-3 relative z-10">
                <Button
                  fullSized
                  type="button"
                  onClick={() => handleCloseModal()}
                >
                  CERRAR
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
