import { UserInfo } from "components/user-info";
import gadget from "assets/img/gadget.svg";
import AvatarCharacter from "components/avatar-character";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getMessages,
  likeMessage,
  sendMessage,
} from "services/messages.service";
import { useAppStore } from "store";
import { formatDistance, set } from "date-fns";
import { BiMessage, BiReply } from "react-icons/bi";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { useModal } from "context/ModalContext";
import { UserPlus } from "@phosphor-icons/react";
import { getCharacterById } from "utils/regions";

export default function Notifications() {
  const { user } = useAppStore();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const [messages, setMessages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const toggleLikeMessage = useCallback(
    (messageId: string) => {
      setMessages((prev) =>
        prev.map((message) => {
          if (message._id === messageId) {
            const updatedMessage = {
              ...message,
              like: !message.like,
              updated: new Date().getTime(),
            };
            // call API to update message
            likeMessage(updatedMessage);

            if (!message.like) {
              // Send Like message
              const payload = {
                _id: `like_${message._id}`,
                origin: message._id,
                to: [message.from],
                content: "❤️ ¡Me gusto!",
                template: null,
                type: "notification",
                sender: "Custom",
                from: user?._id,
                time: new Date().getTime(),
              };

              sendMessage(payload);
            }

            return updatedMessage;
          }
          return message;
        }),
      );
    },
    [user],
  );

  const replyMessage = useCallback(
    async (message: any) => {
      console.log("message", message);
      showModal("message-composer", {
        friend: {
          _id: message.from,
          name: message.nombreUsuario,
          image: {
            original: {
              url: message.image,
            },
          },
        },
      });
    },
    [showModal],
  );

  const loadMessages = useCallback(async () => {
    if (!user) return;
    setLoading(true);
    const data = await getMessages(user._id);
    setMessages(data);
    setLoading(false);
  }, [user]);

  useEffect(() => {
    loadMessages();
    window.localStorage.setItem(
      "game-notification-lastvisit",
      new Date().getTime().toString(),
    );
  }, [loadMessages]);

  const renderMessages = useCallback(() => {
    return (
      <div className="absolute top-20 bottom-3 right-5 md:right-10 flex flex-col gap-2 overflow-y-auto">
        <Button
          className="bg-brand-red text-white rounded-lg ml-5 md:ml-10"
          onClick={() => navigate("/friends")}
        >
          <div className="flex items-center gap-2 justify-between">
            <BiMessage className="h-6 w-6" />
            <span>Enviar nuevo mensaje</span>
          </div>
        </Button>
        <div className="flex-1 flex flex-col gap-2 pl-5 md:pl-10 overflow-y-auto">
          {messages.map((message) => {
            return (
              <div
                key={message._id}
                className="md:w-[320px] flex bg-white rounded-lg p-2 gap-3"
              >
                <div className="relative">
                  {message.sticker ? (
                    <div className="w-20 h-20 bg-gray-200 rounded-lg">
                      <img
                        src={message.sticker}
                        alt=""
                        className="w-full h-full"
                      />
                    </div>
                  ) : null}
                  {message.from && message.sender !== "Custom" ? (
                    message.sticker ? (
                      <div className="border-2 border-white shadow-xl absolute top-0 bottom-0 my-auto right-16 z-20 w-10 h-10 bg-gray-200 rounded-full overflow-hidden">
                        <img
                          src={getCharacterById(message.from)}
                          alt=""
                          className="w-full h-full"
                        />
                      </div>
                    ) : (
                      <div className="w-20 h-20 bg-gray-200 rounded-lg">
                        <img
                          src={getCharacterById(message.from)}
                          alt=""
                          className="w-full h-full"
                        />
                      </div>
                    )
                  ) : message.image ? (
                    <div className="border-2 border-white shadow-xl absolute top-0 bottom-0 my-auto right-16 z-20 w-10 h-10 bg-gray-200 rounded-full overflow-hidden">
                      <img
                        src={message.image}
                        alt=""
                        className="w-full h-full"
                      />
                    </div>
                  ) : null}
                </div>
                <div className="flex-1 flex flex-col justify-between">
                  <div className="flex items-center justify-between">
                    {message.from ? (
                      <p className="font-bold text-xs">
                        {message.nombreUsuario}:
                      </p>
                    ) : (
                      <p></p>
                    )}
                    <p className="text-xs text-gray-600">
                      {formatDistance(new Date(message.time), new Date(), {
                        addSuffix: true,
                      })}
                    </p>
                  </div>
                  <p
                    className="flex-1 mt-1 text-sm leading-tight"
                    dangerouslySetInnerHTML={{
                      __html: message.content,
                    }}
                  ></p>
                  <div className="flex items-center gap-3 pr-2 justify-end">
                    <div
                      className="flex items-center gap-1 text-xs text-red-500"
                      onClick={() => toggleLikeMessage(message._id)}
                    >
                      {message.like ? (
                        <IoMdHeart
                          size={20}
                          className="text-red-500 font-bold"
                        />
                      ) : (
                        <IoMdHeartEmpty size={20} className="text-red-500" />
                      )}
                      me gusta
                    </div>
                    {message.sender === "Custom" ? (
                      <div
                        className="flex items-center gap-1 text-xs text-green-500"
                        onClick={() => replyMessage(message)}
                      >
                        <BiReply size={20} className="text-green-500" />
                        responder
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [messages, navigate, replyMessage, toggleLikeMessage]);

  return useMemo(
    () => (
      <>
        <div
          id="home"
          className={`absolute top-0 left-0 w-full h-full flex flex-col items-center md:justify-center overflow-y-auto overflow-x-hidden md:overflow-hidden scroll-wrapper`}
        >
          <UserInfo />

          <div className="absolute bottom-0 md:relative w-[270px] mt-[100px] md:mt-auto ml-[-150px]">
            <AvatarCharacter responsive />
          </div>

          {loading || (!loading && !messages.length) ? (
            <div className="absolute right-4 md:right-10 bottom-10 md:bottom-20 w-[36vw] md:w-[15vw] animate-fly-fast delay-200">
              <div className="absolute right-0 bottom-full mb-5 bg-white w-[90vw] md:w-96 rounded-xl shadow p-5 space-y-2">
                {!loading ? (
                  <p className="leading-tight">
                    Aquí podrás recibir y enviar mensajes a tus amigos y también
                    recibir avisos importantes sobre nuestras actividades,
                    premios y otras cosas divertidas.
                  </p>
                ) : (
                  <p>Cargando...</p>
                )}
                {!loading ? (
                  <div className="pt-2">
                    <Button onClick={() => navigate("/friends")}>
                      Envía tu primer mensaje
                    </Button>
                  </div>
                ) : null}
              </div>
              <div className="rotate-12 relative">
                <img src={gadget} alt="" className="-scale-x-100" />
              </div>
            </div>
          ) : null}

          {!loading && messages.length ? renderMessages() : null}
        </div>
      </>
    ),
    [messages, loading, navigate, renderMessages],
  );
}
