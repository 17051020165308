import AvatarCharacter from "components/avatar-character";
import { Button } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { actionLog } from "services/actionlog.service";
import { fetchInvite } from "services/friends.service";
import { addFriend } from "services/profile.service";
import { useAppStore } from "store";

export default function Share() {
  const navigate = useNavigate();

  const { user } = useAppStore();

  const [data, setData] = useState<any>();

  const [params] = useSearchParams("inviteCode");

  const inviteCode = params.get("inviteCode");

  const [inviteStatus, setInviteStatus] = useState<string>();

  const loadData = useCallback(async () => {
    console.log("opa", inviteCode);
    if (inviteCode) {
      // do something with inviteCode
      const result = await fetchInvite(inviteCode);
      setData(result);

      const isFriend = user?.friends?.find((f: string) => f === result._id);
      if (isFriend) {
        setInviteStatus("is_friend");
      }
    }
  }, [inviteCode, user]);

  const handleAccept = useCallback(async () => {
    await actionLog("accept_invite", { friend: data._id, share: true });
    await addFriend(`${user?._id}`, data._id);
    setInviteStatus("accepted");
    navigate("/");
  }, [user, data, navigate]);

  const handleDecline = useCallback(() => {
    // do something
    setInviteStatus("declined");
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div
      id="home"
      className={`absolute top-0 left-0 w-full h-full flex flex-col items-center md:justify-center overflow-y-auto overflow-x-hidden md:overflow-hidden scroll-wrapper`}
    >
      {data && data._id !== user?._id ? (
        <>
          <div className="bg-white p-10 rounded-lg flex flex-col items-center justify-center gap-5">
            <h1 className="text-xl">
              <b>{data.name}</b> desea agregarte como amigo.
            </h1>

            {!inviteStatus ? (
              <>
                <p>¿Aceptas?</p>
                <div className=" w-full flex gap-2">
                  <Button
                    className="flex-1 bg-brand-dark-green"
                    onClick={handleAccept}
                  >
                    SÍ
                  </Button>
                  <Button
                    className="flex-1 bg-brand-red"
                    onClick={handleDecline}
                  >
                    NO
                  </Button>
                </div>
              </>
            ) : null}

            {inviteStatus === "accepted" ? (
              <div className="w-full text-brand-dark-green text-center font-bold p-2 rounded-lg border">
                <p>¡Invitación aceptada!</p>
              </div>
            ) : null}

            {inviteStatus === "declined" ? (
              <div className="w-full text-brand-red text-center font-bold p-2 rounded-lg border">
                <p>¡Invitación rechazada!</p>
              </div>
            ) : null}

            {inviteStatus === "is_friend" ? (
              <div className="w-full text-brand-blue text-center font-bold p-2 rounded-lg border">
                <p>¡Ya son amigos!</p>
              </div>
            ) : null}

            <Button
              onClick={() => {
                navigate("/");
              }}
            >
              CONTINUAR JUGANDO
            </Button>
          </div>

          <div className="absolute w-48 md:w-64 h-[80px] md:h-[200px] left-0 my-auto top-0 bottom-0 pointer-events-none">
            <AvatarCharacter responsive preset={data?.avatar} />
          </div>
          <div className="absolute w-48 md:w-64 h-[80px] md:h-[200px] right-0 my-auto top-0 bottom-0 -scale-x-100 pointer-events-none">
            <AvatarCharacter responsive />
          </div>
        </>
      ) : (
        <>
          {!data ? (
            <div className="bg-white p-10 rounded-lg flex flex-col items-center justify-center">
              <h1>Cargando...</h1>
            </div>
          ) : data._id === user?._id ? (
            <div className="bg-white p-10 rounded-lg flex flex-col items-center justify-center">
              <h1 className="mb-3">No puedes invitarte tú mismo.</h1>
              <Button
                onClick={() => {
                  navigate("/");
                }}
              >
                CONTINUAR JUGANDO
              </Button>
            </div>
          ) : null}
          <div className="absolute w-48 md:w-64 h-[80px] md:h-[200px] left-0 my-auto top-0 bottom-0 pointer-events-none">
            <AvatarCharacter responsive preset={data?.avatar} />
          </div>
          <div className="absolute w-48 md:w-64 h-[80px] md:h-[200px] right-0 my-auto top-0 bottom-0 -scale-x-100 pointer-events-none">
            <AvatarCharacter responsive />
          </div>
        </>
      )}
    </div>
  );
}
