import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";

import { Button } from "flowbite-react";
import Gem from "components/gem";
import { useModal } from "context/ModalContext";
import {
  avatarTabOptionsImage,
  avatarTabPrice,
} from "components/avatar-creator/avatar-creator-options";
import { getVirtualGood, purchaseItem } from "services/store.service";
import Point from "components/ui/point";
import { ca } from "date-fns/locale";
import { useAppStore } from "store";
import { set } from "date-fns";

export function AvatarModal(props: any) {
  ReactModal.setAppElement("#modal");

  const modalID = "avatar";

  const [modalIsOpen, setIsOpen] = useState(false);
  const { openModal, closeModal, modalData } = useModal();
  const { user, refreshUser } = useAppStore();
  const [itemDetails, setItemDetails] = useState<any>();
  const [purchaseError, setPurchaseError] = useState<any>();

  function handleCloseModal() {
    closeModal();
    setItemDetails(undefined);
    setPurchaseError(undefined);
  }

  function afterOpenModal() {}

  async function loadItemDetails(item: any) {
    const virtualGoodId = avatarTabPrice[item.key][item.value];
    try {
      setItemDetails(undefined);
      const result = await getVirtualGood(virtualGoodId);
      setItemDetails(result);
    } catch (error) {
      console.log(error);
    }
  }

  const handlePurchase = useCallback(async () => {
    if (!itemDetails || !user) {
      return;
    }

    try {
      const result = await purchaseItem(itemDetails._id, user._id);
      if (result.status === "OK") {
        refreshUser();
        closeModal();
      } else {
        setPurchaseError(
          "No cumples los requisitos para comprar este artículo.",
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [itemDetails, user, refreshUser, closeModal]);

  useEffect(() => {
    if (openModal === modalID) {
      setIsOpen(true);
      if (modalData) {
        loadItemDetails(modalData.item);
      }
    } else {
      setIsOpen(false);
    }
  }, [openModal, modalData]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Badge Modal"
        portalClassName="relative z-40"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 px-10 w-[300px] max-w-[450px] flex flex-col items-center flex-none mx-5 gap-2">
              <h1 className="text-xl lg:text-3xl mt-4 font-bold text-blue-500 text-center">
                Tienda Avatar
              </h1>

              <div className="text-center w-full">
                <p className="text-gray-500 text-center">Saldo actual</p>
                <div className="grid grid-cols-3  border rounded-xl">
                  <div className="px-2 py-1 flex-col flex items-center">
                    <span className="text-xs md:text-sm">Conocimento</span>
                    <div className="flex items-center space-x-2">
                      <Gem size={18} type="knowledge" />{" "}
                      <b>{user?.point_categories?.knowledge || 0}</b>
                    </div>
                  </div>
                  <div className="px-2 py-1  flex-col flex items-center">
                    <span className="text-xs md:text-sm">Habilidades</span>
                    <div className="flex items-center space-x-2">
                      <Gem size={18} type="skill" />{" "}
                      <b>{user?.point_categories?.skill || 0}</b>
                    </div>
                  </div>
                  <div className="px-2 py-1  flex-col flex items-center">
                    <span className="text-xs md:text-sm">Motivación</span>
                    <div className="flex items-center space-x-2">
                      <Gem size={18} type="motivation" />{" "}
                      <b>{user?.point_categories?.motivation || 0}</b>
                    </div>
                  </div>
                </div>
              </div>

              {modalData ? (
                <div className="relative w-[128px]">
                  <img
                    src={
                      avatarTabOptionsImage[modalData.item.key][
                        modalData.item.value
                      ]
                    }
                    className={`w-full h-full ring-1 shadow-xl object-cover rounded-full overflow-hidden`}
                    alt=""
                  />
                </div>
              ) : null}

              <div className="w-full text-center">
                {itemDetails?.name && (
                  <p className="text-orange-500 font-bold text-center text-xl">
                    {itemDetails?.name}
                  </p>
                )}

                {itemDetails?.description && (
                  <p className="text-gray-500 text-center">
                    {itemDetails?.description}
                  </p>
                )}

                {itemDetails?.requires && (
                  <div className="border rounded-xl py-1 px-3 mx-3">
                    <p>Este artículo de avatar cuesta:</p>
                    <div className="flex justify-center">
                      {itemDetails?.requires
                        ? itemDetails?.requires.map(
                            (point: any, index: number) => (
                              <div
                                key={index}
                                className="flex items-center space-x-2 bg-gray-100 rounded-full p-1"
                              >
                                <Point name={point.item} />
                                <strong>{point.total}</strong>
                              </div>
                            ),
                          )
                        : null}
                    </div>
                  </div>
                )}
              </div>

              {purchaseError && (
                <div className="text-red-500 text-center">{purchaseError}</div>
              )}

              <div className="flex gap-5 py-3 relative z-10">
                <Button
                  fullSized
                  type="button"
                  color={"red"}
                  onClick={() => handleCloseModal()}
                >
                  CERRAR
                </Button>
                <Button
                  fullSized
                  type="button"
                  onClick={() => handlePurchase()}
                >
                  COMPRAR
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
